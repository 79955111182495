import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
import {modalLoading} from '../../docxtemplater/element';
import Notifications from 'components/Notifications';
import SearchInput from 'components/SearchInput';
import { notificationsData } from 'demos/header';
import { Redirect, withRouter } from 'react-router-dom';
import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Input,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import bn from 'utils/bemnames';

const bem = bn.create('header');

const MdNotificationsActiveWithBadge = withBadge({
  size: 'md',
  color: 'primary',
  style: {
    top: -10,
    right: -10,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  children: <small>5</small>,
})(MdNotificationsActive);

class Header extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      isOpenNotificationPopover: false,
      isNotificationConfirmed: false,
      isOpenUserCardPopover: false,
      yearFilter: localStorage.getItem("yearFilter"),
      modal: false
    };
    this.handleYearFilter = this.handleYearFilter.bind(this);
    //this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  componentDidMount(){
    //console.log("year Filter : "+localStorage.getItem("yearFilter"))
  }
  // state = {
  //   isOpenNotificationPopover: false,
  //   isNotificationConfirmed: false,
  //   isOpenUserCardPopover: false,
  // };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
    });

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true });
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
    //document.querySelector('.cr-btnMenu').classList.toggle('cr-btnMenu--open');
  };

  handleSignout = () => {
    localStorage.clear();
    window.location.href = "/";
  }
  handleYearFilter(event){
    
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const key = target.name;
    localStorage.setItem("yearFilter", value);
    // localStorage.removeItem("pdtKoordinator");
    // localStorage.removeItem("pdtPPK");
    // localStorage.removeItem("pdtPPBJ");
    this.setState({modal:true});
    this.getPenandatangan();

    setTimeout(() => {
      window.location.href = window.location.pathname;
      this.setState({modal:false})
    }, 1500);

    
  }
  getPenandatangan=()=>{
    var value = localStorage.getItem("yearFilter");
    const ro1 = {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ role: "Koordinator", year:value })
    };
    fetch(process.env.REACT_APP_URL_API+'/rest/lookupPdt.php', ro1)
        .then(response => response.json())
        .then(respon => {
          var dataAPI = respon;
          
          if(dataAPI.response_code != 200){
            this.setState({ message: dataAPI.message });
          }else{
            localStorage.setItem("pdtKoordinator", JSON.stringify(dataAPI.data));
            console.log("pdtKoordinator");
            console.log(dataAPI.data);

            //this.setState({ pdtKoordinator : dataAPI});
          }
        });

    const ro2 = {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ role: "PPBJ", year: value })
    };
    fetch(process.env.REACT_APP_URL_API+'/rest/lookupPdt.php', ro2)
        .then(response => response.json())
        .then(respon => {
          var dataAPI = respon;
          
          if(dataAPI.response_code != 200){
            this.setState({ message: dataAPI.message });
          }else{
            localStorage.setItem("pdtPPBJ", JSON.stringify(dataAPI.data));
            //this.setState({ pdtPPBJ : dataAPI});
          }
        });

    const ro3 = {
      method: 'POST',
      //headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ role: "PPK", year: value })
    };
    fetch(process.env.REACT_APP_URL_API+'/rest/lookupPdt.php', ro3)
        .then(response => response.json())
        .then(respon => {
          var dataAPI = respon;
          
          if(dataAPI.response_code != 200){
            this.setState({ message: dataAPI.message });
          }else{
            localStorage.setItem("pdtPPK", JSON.stringify(dataAPI.data));
            //this.setState({ pdtPPK : dataAPI});
          }
        });
        
  }
  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
        {modalLoading(this.state.modal)}
        <Nav 
        style={{
          
        }}
        title="Menu"
        navbar className="mr-2 cr-btnMenu">
          <Button 
              outline onClick={this.handleSidebarControlButton}
            >
            <MdClearAll size={20} />
          </Button>

          <NavItem className="d-inline-flex">
            <Input 
                style={{fontSize:14, marginLeft:10,}}
                type={"select"}
                //name={xs.id}
                //id={x.id}
                //placeholder={x.placeholder}
                value={this.state.yearFilter}
                onChange={this.handleYearFilter}
            >
              {/* {x.type=="select"?
                x.options.map(y=>(
                  <option value={y.value}>{y.label}</option>
                ))
              :null} */}
              
              <option value={2021}>2021</option>
              <option value={2022}>2022</option>
              <option value={2023}>2023</option>
              <option value={2024}>2024</option>
            </Input>
          </NavItem>
        </Nav>
        {/* <Nav navbar>
          <SearchInput />
        </Nav> */}

        <Nav navbar className={bem.e('nav-right')}>
          {/* NAVBAR NOTIFICATION */}
          {/* <NavItem className="d-inline-flex">
            <NavLink id="Popover1" className="position-relative">
              {isNotificationConfirmed ? (
                <MdNotificationsNone
                  size={25}
                  className="text-secondary can-click"
                  onClick={this.toggleNotificationPopover}
                />
              ) : (
                <MdNotificationsActiveWithBadge
                  size={25}
                  className="text-secondary can-click animated swing infinite"
                  onClick={this.toggleNotificationPopover}
                />
              )}
            </NavLink>
            <Popover
              placement="bottom"
              isOpen={this.state.isOpenNotificationPopover}
              toggle={this.toggleNotificationPopover}
              target="Popover1"
            >
              <PopoverBody>
                <Notifications notificationsData={notificationsData} />
              </PopoverBody>
            </Popover>
          </NavItem> */}

          <NavItem>
            <NavLink id="Popover2">
              <Avatar
                onClick={this.toggleUserCardPopover}
                className="can-click"
              />
            </NavLink>
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <PopoverBody className="p-0 border-light">
                <UserCard
                  title={localStorage.getItem('user_name')}
                  //subtitle="jane@jane.com"
                  //text="Last updated 3 mins ago"
                  className="border-light"
                >
                  <ListGroup flush>
                    <ListGroupItem tag="button" action className="border-light" onClick={()=>{
                      //window.location.href="/profile"
                      //this.props.history.push("/profile");
                      window.location = '/profile';
                      //return <Redirect to="/profile" />
                    }}>
                      <MdPersonPin /> Profile
                    </ListGroupItem>
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdInsertChart /> Stats
                    </ListGroupItem>
                    <ListGroupItem tag="button" action className="border-light">
                      <MdMessage /> Messages
                    </ListGroupItem> */}
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdSettingsApplications /> Settings
                    </ListGroupItem> */}
                    {/* <ListGroupItem tag="button" action className="border-light">
                      <MdHelp /> Help
                    </ListGroupItem> */}
                    <ListGroupItem tag="button" action className="border-light" onClick={this.handleSignout}>
                      <MdExitToApp /> Signout
                    </ListGroupItem>
                  </ListGroup>
                </UserCard>
              </PopoverBody>
            </Popover>
          </NavItem>
        </Nav>
      </Navbar>
    );
  }
}

export default Header;
